<template>
  <div id="app">
    <div class="col pb-5">
      <div class="row">
        <div class="banner-top col">
          <a href="/">
            <img class="img-logo" src="./assets/logo.png" />
          </a>
        </div>
      </div>
      <div class="col-12 justify-content-center">
        <div class="row">
          <div class="topnav" id="myTopnav">
            <li>
              <router-link to="/" class="style-router nav-link"
                >Inicio</router-link
              >
            </li>
            <li>
              <router-link to="/nosotros" class="style-router nav-link"
                >Nosotros</router-link
              >
            </li>
            <li>
              <router-link class="nav-link style-router" to="/servicios"
                >Servicios</router-link
              >
            </li>
            <li>
              <router-link class="nav-link style-router" to="/empresa"
                >Empresa</router-link
              >
            </li>
            <li>
              <router-link class="nav-link style-router" to="/alianzas"
                >Alianzas</router-link
              >
            </li>
            <li>
              <router-link class="nav-link style-router" to="/clientes"
                >Clientes</router-link
              >
            </li>
            <li>
              <router-link class="nav-link style-router" to="/deinteres"
                >De Interes</router-link
              >
            </li>
            <li>
              <a
                class="nav-link style-router"
                href="mailto:jpcampuzano@olce.com.co"
                >Contacto</a
              >
            </li>
            <a v-on:click="metodo" class="icon pl-5">
              <i>
                <b-icon-arrow-bar-down />
              </i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <div class="footer-inicio col pb-5">
      <div class="container pt-5">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-xl-4">
            <iframe
              width="251"
              height="182"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=olce%20sas%20&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-4">
            <div class="row pb-1">
              <div class="col-2">
                <br />
              </div>
              <div class="col-8">
                <p class="txtnormal-negrilla text-uppercase text-center">
                  OFICINA PRINCIPAL
                  <br />MEDELLÍN
                </p>
              </div>
              <div class="col-2">
                <br />
              </div>
            </div>
            <div class="pb-1">
              <p class="segundotxt-inicio-nosotros text-center border-footer">
                Cr 65b # 32D - 48, Antioquia, Medellín
              </p>
            </div>
            <div class="row pb-1">
              <div class="col-4">
                <p class="segundotxt-inicio-nosotros text-center border-footer">
                  CARTAGENA
                </p>
              </div>
              <div class="col-4">
                <p class="segundotxt-inicio-nosotros text-center border-footer">
                  BOGOTÁ
                </p>
              </div>
              <div class="col-4">
                <p class="segundotxt-inicio-nosotros text-center border-footer">
                  BUENAVENTURA
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-4">
            <img class="img-footer" src="./assets/logo.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  methods: {
    metodo: function myFunction() {
      var x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
  },
};
</script>
<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  margin-top: 0%;
  margin-left: 0%;
  margin-right: 0%;
}

.footer-inicio {
  background-image: url("./assets/alianzas-inicio.png");
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30%;
  margin: 0;
  padding: 0;
  position: relative;
}
.img-footer {
  padding-left: 30%;
  width: 60%;
  height: 90%;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.segundotxt-inicio-nosotros {
  font-size: 15px;
  font-family: Texto-normal;
}

.txtnormal-negrilla {
  font-family: Texto-normal;
  font-weight: bold;
  font-size: 20px;
}

.banner-top {
  background-image: url("./assets/franja-banner-top.png");
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30%;
  margin: 0;
  padding: 0;
  position: relative;
}
.img-logo {
  width: 20%;
  height: 30%;
  padding-left: 12%;
  padding-top: 2%;
  padding-bottom: 2%;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.topnav {
  overflow: hidden;
  border-bottom: 5px solid;
  border-image: url("./assets/navbar.linea.png") 30 stretch;
  margin-left: 15%;
}
.border-footer {
  border-bottom: 1px solid;
  border-color: black;
}
.topnav li {
  float: left;
  display: block;
  color: rgb(135, 136, 136);
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  font-family: Texto-menu;
}

div li {
  padding: 1px 0;
  display: inline-block;
  position: relative;
}
.style-router {
  display: block;
  color: rgb(135, 136, 136);
  text-decoration: none;
  font-size: 17px;
  font-family: Texto-menu;
}
.topnav li:hover:before {
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9;
  background: linear-gradient(to right, #0000ff 50%, white 50%);
}
.topnav li:hover:after {
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 9;
  background: linear-gradient(to right, white 50%, #0000ff 50%);
}
.topnav .icon {
  display: none;
}
@media screen and (max-width: 600px) {
  .topnav li:not(:first-child) {
    display: none;
  }
  .topnav .icon {
    float: left;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
    overflow-y: scroll;
  }
  .topnav.responsive .icon {
    position: left;
    left: 0;
    top: 0;
  }
  .topnav.responsive li {
    float: none;
    display: block;
    text-align: left;
  }
}

@font-face {
  font-family: Titulo-grande;
  src: url("./assets/fonts/BebasNeue Bold.otf");
}
@font-face {
  font-family: Texto-normal;
  src: url("./assets/fonts/Lato-Light.ttf");
}
@font-face {
  font-family: Texto-menu;
  src: url("./assets/fonts/Montserrat-Light.otf");
}
</style>
