<template>
  <div>
    <div class="container pt-5">
      <div class="row">
        <div class="col-sm">
          <img class="img-nosotros-inicio" src="../assets/inicio.us.png" />
        </div>
        <div class="col-sm">
          <p class="nosotrostxt-inicio text-uppercase text-center">nosotros</p>
          <p
            class="segundotxt-inicio-nosotros text-center"
          >Prestamos servicios de acompañamiento en la intermediación Aduanera y Asesoria en el proceso de Importación y Exportación.</p>
        </div>
      </div>
    </div>
    <div class="azul-inicio col">
      <div class="container pt-5">
        <div class="row">
          <div class="col-sm-1">
            <br />
          </div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                <div class="card carta" style="width:100%;height: 100%;">
                  <img
                    class="card-img-top"
                    src="../assets/camion.png"
                    alt="Card image"
                    style="width:100%;height: 40%;"
                  />
                  <div class="card-body bg-operativo">
                    <h4 class="card-title title-operativo text-white text-center">Operativo</h4>
                    <p class="card-text text-white txtoperativo-inicio txtnormal">
                      Nuestro acompañamiento busca el análisis de procedimientos
                      que acorten tiempos logrando una eficiencia operacional permanente y que conlleve
                      a un margen de ganancia superior, que bien puede ser por la prontitud en las
                      operaciones o por el uso de mecanismos o figuras que así lo permitan.
                    </p>
                  </div>
                </div>
                <br />
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                <div class="card carta" style="width:100%;height: 100%;">
                  <img
                    class="card-img-top"
                    src="../assets/avion.png"
                    alt="Card image"
                    style="width:100%;height: 40%;"
                  />
                  <div class="card-body bg-administrativo">
                    <h4
                      class="card-title title-administrativo text-white text-center"
                    >Administrativo</h4>
                    <p class="card-text text-white txtnormal">
                      Nuestra función en este aspecto es la de un acompañamiento permanente,
                      queremos participar en sus actividades diarias buscando que nuestros
                      clientes dediquen la mayor parte de su tiempo a lo que consideran importante y
                      necesario dentro de su objeto social.
                    </p>
                  </div>
                </div>
                <br />
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                <div class="card carta" style="width:100%;height: 100%; ">
                  <img
                    class="card-img-top"
                    src="../assets/barco.png"
                    alt="Card image"
                    style="width:100%;height: 40%;"
                  />
                  <div class="card-body bg-financiero">
                    <h4 class="card-title title-financiero text-white text-center">Financiero</h4>
                    <p class="card-text text-white txtnormal">
                      El acompañamiento financiero está enfocado principalmente a
                      la minimización del costo en las operaciones de comercio exterior,
                      realizamos un estudio concienzudo y minucioso de los procesos actuales.
                    </p>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <br />
          </div>
          <div class="col-sm-1">
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="empresa-inicio col">
      <div class="container pt-5">
        <div class="row">
          <div class="col-sm-2">
            <br />
          </div>
          <div class="col-sm-8 pb-5">
            <div class="row pb-5">
              <div class="col-12 pb-5 text-center">
                <p class="empresatxt-inicio text-uppercase text-center">empresa</p>
              </div>
              <div class="col-12">
                <p
                  class="text-center pb-5"
                >O.L.C.E S.A.S. Es una empresa que fue concebida con una visión de negocio que no solo busca la generación de valor a través de sus actividades, sino que también tiene un objetivo específico y es el de la obtención de rentabilidad para nuestros clientes derivada de todos sus procesos de comercio exterior.</p>
              </div>
              <div class="col-12 justify-content-center">
                <div class="row">
                  <div class="topnav2" id="myTopnav2">
                    <a :href="`./estadosfinancieros.pdf`" target="_blank">Estados Financieros</a>
                    <li>
                      <router-link class="nav-link style-router" to="/empresa">Talento Humano</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link style-router" to="/empresa">Sucursales</router-link>
                    </li>
                    <a v-on:click="hide2" class="icon">
                      <i>
                        <b-icon icon="map"></b-icon>
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="alianzas-inicio col">
      <div class="container pt-5">
        <div class="row">
          <div class="col-sm-2">
            <br />
          </div>
          <div class="col-sm-8 pb-5">
            <div class="row pb-5">
              <div class="col-12 pb-5 text-center">
                <p class="alianzastxt-inicio text-uppercase text-center">alianzas</p>
              </div>
              <div class="container pt-5">
                <div class="row">
                  <div class="col-sm-1">
                    <br />
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                        <img class="img-alianzas" src="../assets/alianzas1.png" />
                        <br />
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                        <img class="img-alianzas" src="../assets/alianzas2.png" />
                        <br />
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                        <img class="img-alianzas" src="../assets/alianzas3.png" />
                        <br />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                        <img class="img-alianzas" src="../assets/alianzas4.png" />
                        <br />
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                        <br />
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 pb-5">
                        <img class="img-alianzas" src="../assets/alianzas5.png" />
                        <br />
                      </div>
                    </div>
                    <br />
                  </div>
                  <div class="col-sm-1">
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexC",
  methods: {
    hide: function myFunction() {
      var x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
    hide2: function myFunction() {
      var x = document.getElementById("myTopnav2");
      if (x.className === "topnav2") {
        x.className += " responsive";
      } else {
        x.className = "topnav2";
      }
    }
  }
};
</script>
<style scoped>
.empresa-inicio {
  background-image: url("../assets/empresa-inicio.png");
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30%;
  margin: 0;
  padding: 0;
  position: relative;
}
.title-operativo {
  border-bottom: solid 3px;
  border-color: white;
}
.title-administrativo {
  border-bottom: solid 3px;
  border-color: white;
}
.title-financiero {
  border-bottom: solid 3px;
  border-color: white;
}
.carta {
  border: none;
}
.bg-operativo {
  background-color: rgb(141, 108, 73);
}
.bg-administrativo {
  background-color: rgb(193, 193, 72);
}
.bg-financiero {
  background-color: rgb(205, 81, 72);
}
.alianzas-inicio {
  background-image: url("../assets/alianzas-inicio.png");
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30%;
  margin: 0;
  padding: 0;
  position: relative;
}
.azul-inicio {
  background-image: url("../assets/azul-inicio.png");
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30%;
  margin: 0;
  padding: 0;
  position: relative;
}
.img-nosotros-inicio {
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.segundotxt-inicio-nosotros {
  font-size: 15px;
  font-family: Texto-normal;
}
.txtnormal {
  font-family: Texto-normal;
}
.txtnormal-negrilla {
  font-family: Texto-normal;
  font-weight: bold;
  font-size: 20px;
}
.nosotrostxt-inicio {
  font-size: 87px;
  padding-top: 10%;
  border-bottom: 5px solid;
  border-image: url("../assets/navbar.linea.png") 30 stretch;
  font-family: Titulo-grande;
}
.border-footer {
  border-bottom: 1px solid;
  border-color: black;
}
.empresatxt-inicio {
  font-size: 87px;
  font-family: Titulo-grande;
}
.alianzastxt-inicio {
  font-size: 87px;
  padding-top: 10%;
  font-family: Titulo-grande;
}
.banner-top {
  background-image: url("../assets/franja-banner-top.png");
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30%;
  margin: 0;
  padding: 0;
  position: relative;
}
.img-logo {
  width: 20%;
  height: 30%;
  padding-left: 12%;
  padding-top: 2%;
  padding-bottom: 2%;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.img-alianzas {
  width: 100%;
  height: 100%;
  padding-left: 12%;
  padding-top: 2%;
  padding-bottom: 2%;
  -webkit-background-size: cover;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
div a {
  padding: 1px 0;
  display: inline-block;
  position: relative;
}
.topnav2 {
  overflow: hidden;
  border-bottom: 5px solid;
  margin-left: 20%;
  border-image: url("../assets/navbar.linea.png") 30 stretch;
}
.topnav li {
  float: left;
  display: block;
  color: rgb(135, 136, 136);
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  font-family: Texto-menu;
}
.topnav li:hover:before {
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9;
  background: linear-gradient(to right, #0000ff 50%, white 50%);
}
.topnav li:hover:after {
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 9;
  background: linear-gradient(to right, white 50%, #0000ff 50%);
}
.topnav2 a {
  float: left;
  display: block;
  color: rgb(135, 136, 136);
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 15px;
  font-family: Texto-menu;
}
.topnav2 a:hover:before {
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9;
  background: linear-gradient(to right, #0000ff 50%, white 50%);
}
.topnav2 a:hover:after {
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 9;
  background: linear-gradient(to right, white 50%, #0000ff 50%);
}
.topnav2 .icon {
  display: none;
}
@media screen and (max-width: 600px) {
  .topnav2 a:not(:first-child) {
    display: none;
  }
  .topnav2 a.icon {
    float: right;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .topnav2.responsive {
    position: relative;
  }
  .topnav2.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav2.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive li {
    float: none;
    display: block;
    text-align: left;
  }
}
@font-face {
  font-family: Titulo-grande;
  src: url("../assets/fonts/BebasNeue Bold.otf");
}
@font-face {
  font-family: Texto-normal;
  src: url("../assets/fonts/Lato-Light.ttf");
}
@font-face {
  font-family: Texto-menu;
  src: url("../assets/fonts/Montserrat-Light.otf");
}
</style>